import React, { useState, useEffect } from "react";
import EditableText from "./EditableText";
import { FaTrash } from "react-icons/fa";

export default function Licenses() {
  const [data, setData] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saveMessage, setSaveMessage] = useState("");

  const getData = () => {
    fetch("https://plugins.sw-dev.nl/licenses.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      cache: "no-cache",
    })
      .then((response) => response.json())
      .then((myJson) => {
        const formattedData = Object.entries(myJson).map(
          ([key, value]) => ({
            id: key,
            license: value.license,
          })
        );
        setData(formattedData);
        setUnsavedChanges(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [unsavedChanges]);

  const handleIDChange = (id, newText) => {
    setData(
      data.map((item) =>
        item.id === id ? { ...item, id: newText } : item
      )
    );
    setUnsavedChanges(true);
  };

  const handleLicenseChange = (license, newText) => {
    setData(
      data.map((item) =>
        item.license === license ? { ...item, license: newText } : item
      )
    );
    setUnsavedChanges(true);
  };

  const saveData = () => {
    const formattedData = data.reduce((acc, item) => {
      acc[item.id] = { license: item.license };
      return acc;
    }, {});

    fetch("https://plugins.sw-dev.nl/update_licenses.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedData),
    })
      .then((response) => response.json())
      .then((data) => {
        setSaveMessage("Data successfully saved!");
        setUnsavedChanges(false);
        setTimeout(() => setSaveMessage(""), 3000);
      })
      .catch((error) => {
        console.error("Error during saving data:", error);
      });
  };

  const addRow = () => {
    setData([
      ...data,
      {
        id: "",
        license: "",
      },
    ]);
    setUnsavedChanges(true);
  };

  const handleDelete = (id) => {
    if (!window.confirm("Weet je zeker dat je deze rij wilt verwijderen?")) {
      return;
    }
    setData(data.filter((item) => item.id !== id));
    setUnsavedChanges(true);
  };

  return (
    <div className="p-4 bg-gray-50">
      <div className="w-full">
        <div className="flex w-full">
          <div className="px-2 w-48 min-w-48 border border-gray-300 bg-blue-100 text-gray-800 font-semibold">
            Naam
          </div>
          <div className="px-2 flex-1 min-w-48 border border-gray-300 border-l-0 bg-blue-100 text-gray-800 font-semibold">
            Licentie
          </div>
        </div>
        {data.map((item, index) => (
          <div
            className={`flex w-full licenserow min-h-8 ${
              index % 2 === 0 ? "bg-gray-100" : "bg-white"
            }`}
            key={item.id}
          >
            <div className="px-2 w-48 min-w-48 border border-gray-300 border-t-0">
              <EditableText
                text={item.id}
                onTextChange={(newText) =>
                  handleIDChange(item.id, newText)
                }
              />
            </div>
            <div className="px-2 flex-1 border border-gray-300 border-l-0 border-t-0">
              <EditableText
                text={item.license}
                onTextChange={(newText) =>
                  handleLicenseChange(item.license, newText)
                }
              />
            </div>
            <div className="px-2 flex-1 border border-gray-300 border-l-0 border-t-0 max-w-8 inline-flex items-center">
              <FaTrash
                className="cursor-pointer text-red-800"
                onClick={() => handleDelete(item.id)}
              />
            </div>
          </div>
        ))}
      </div>
      {saveMessage && (
        <div className="mt-4 text-green-600 font-semibold">
          {saveMessage}
        </div>
      )}
      {unsavedChanges && (
        <div className="mt-4 text-red-600 font-semibold">
          There are unsaved changes!
        </div>
      )}
      <div className="flex gap-x-4">
        <div
          onClick={addRow}
          className="mt-4 px-4 cursor-pointer text-white bg-green-700 hover:bg-green-800 text-center p-2 rounded"
        >
          Toevoegen
        </div>
        <div
          onClick={saveData}
          className="mt-4 px-4 cursor-pointer text-white bg-purple-700 hover:bg-purple-800 text-center p-2 rounded"
        >
          Opslaan
        </div>
      </div>
    </div>
  );
}
