import React from "react";
import { Link } from "react-router-dom";

export default function Sidebar() {
	return (
		<>
			<div className="w-60 text-left bg-saleswizard px-2 py-2 flex flex-col">
				<img
					src="logo.jpg"
					alt="Logo"
					className="w-24 m-auto my-0"
				/>
				<div className="px-8 flex flex-col mt-4 pt-8">
					<Link
						className="text-white mb-2"
						to="/"
					>
						Dashboard
					</Link>
					<Link
						className="text-white mb-2"
						to="/licenses"
					>
						Licenses
					</Link>
					<Link
						className="text-white mb-2"
						to="/plugins"
					>
						Plugins
					</Link>
					<Link
						className="text-white mb-2"
						to="/upload"
					>
						Upload
					</Link>
				</div>
			</div>
		</>
	);
}
