import React from "react";
import FileUpload from "./FileUpload";

export default function Content() {
	return (
		<div className="g-gray-50 max-h-full h-full">
			<FileUpload />
		</div>
	);
}
