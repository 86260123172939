import React, { useState, useEffect } from "react";
import EditableText from "./EditableText";
import { FaTrash } from "react-icons/fa";

export default function Plugins() {
  const [data, setData] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [saveMessage, setSaveMessage] = useState("");

  const getData = () => {
    fetch("https://plugins.sw-dev.nl/plugins.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      cache: "no-cache",
    })
      .then((response) => response.json())
      .then((myJson) => {
        const formattedData = Object.entries(myJson).map(
          ([key, value]) => ({
            id: key,
            slug: value.slug,
            name: value.name,
            url: value.url,
            version: value.version,
          })
        );
        setData(formattedData);
        setUnsavedChanges(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [unsavedChanges]);

  const handleNameChange = (id, newName) => {
    setData(
      data.map((item) => (item.id === id ? { ...item, name: newName } : item))
    );
    setUnsavedChanges(true);
  };

  const handleSlugChange = (id, newSlug) => {
    setData(
      data.map((item) => (item.id === id ? { ...item, slug: newSlug } : item))
    );
    setUnsavedChanges(true);
  };

  const handleUrlChange = (id, newUrl) => {
    setData(
      data.map((item) => (item.id === id ? { ...item, url: newUrl || "" } : item))
    );
    setUnsavedChanges(true);
  };

  const handleVersionChange = (id, newVersion) => {
    setData(
      data.map((item) => (item.id === id ? { ...item, version: newVersion || "" } : item))
    );
    setUnsavedChanges(true);
  };

  const saveData = () => {
    const formattedData = data.reduce((acc, item) => {
      acc[item.id] = {
        name: item.name,
        slug: item.slug,
        ...(item.url && { url: item.url }),
        ...(item.version && { version: item.version }),
      };
      return acc;
    }, {});

    fetch("https://plugins.sw-dev.nl/update_plugins.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedData),
    })
      .then((response) => response.json())
      .then((data) => {
        setSaveMessage("Data is succesvol opgeslagen!");
        setUnsavedChanges(false);
        setTimeout(() => setSaveMessage(""), 3000);
      })
      .catch((error) => {
        console.error("Error during saving data:", error);
      });
  };

  const addRow = () => {
    setData([
      ...data,
      {
        id: "",
        license: "",
        url: "",
        version: "",
      },
    ]);
    setUnsavedChanges(true);
  };

  const handleDelete = (id) => {
    if (!window.confirm("Weet je zeker dat je deze rij wilt verwijderen?")) {
      return;
    }
    setData(data.filter((item) => item.id !== id));
    setUnsavedChanges(true);
  };

  return (
    <div className="p-4">
      <div className="w-full">
        <div className="flex w-full">
          <div className="px-2 w-48 min-w-96 border border-gray-300 bg-blue-100 text-gray-800 font-semibold">
            Naam
          </div>
          <div className="px-2 flex-1 min-w-48 border border-gray-300 border-l-0 bg-blue-100 text-gray-800 font-semibold">
            Slug
          </div>
          <div className="px-2 flex-1 min-w-48 border border-gray-300 border-l-0 bg-blue-100 text-gray-800 font-semibold">
            Url
          </div>
          <div className="px-2 flex-1 min-w-48 border border-gray-300 border-l-0 bg-blue-100 text-gray-800 font-semibold">
            Version
          </div>
          <div className="px-2 flex-1 max-w-8 border border-gray-300 border-l-0 bg-blue-100 text-gray-800 font-semibold"></div>
        </div>
        {data.map((item, index) => (
          <div
            className={`flex w-full licenserow min-h-8 ${
              index % 2 === 0 ? "bg-gray-100" : "bg-white"
            }`}
            key={item.id}
          >
            <div className="px-2 w-96 min-w-96 border border-gray-300 border-t-0">
              <EditableText
                text={item.name}
                onTextChange={(newText) => handleNameChange(item.id, newText)}
              />
            </div>
            <div className="px-2 flex-1 border border-gray-300 border-l-0 border-t-0">
              <EditableText
                text={item.slug}
                onTextChange={(newText) => handleSlugChange(item.id, newText)}
              />
            </div>
            <div className="px-2 flex-1 border border-gray-300 border-l-0 border-t-0">
              <EditableText
                text={item.url}
                onTextChange={(newText) => handleUrlChange(item.id, newText)}
              />
            </div>
            <div className="px-2 flex-1 border border-gray-300 border-l-0 border-t-0">
              <EditableText
                text={item.version}
                onTextChange={(newText) => handleVersionChange(item.id, newText)}
              />
            </div>
            <div className="px-2 flex-1 border border-gray-300 border-l-0 border-t-0 max-w-8 inline-flex items-center">
              <FaTrash
                className="cursor-pointer text-red-800"
                onClick={() => handleDelete(item.id)}
              />
            </div>
          </div>
        ))}
      </div>
      {saveMessage && (
        <div className="mt-4 text-green-600 font-semibold">
          {saveMessage}
        </div>
      )}
      {unsavedChanges && (
        <div className="mt-4 text-red-600 font-semibold">
          Er zijn onopgeslagen veranderingen.
        </div>
      )}
      <div className="flex gap-x-4">
        <div
          onClick={addRow}
          className="mt-4 px-4 cursor-pointer text-white bg-green-700 hover:bg-green-800 text-center p-2 rounded"
        >
          Toevoegen
        </div>
        <div
          onClick={saveData}
          className="mt-4 px-4 cursor-pointer text-white bg-purple-700 hover:bg-purple-800 text-center p-2 rounded"
        >
          Opslaan
        </div>
      </div>
    </div>
  );
}
