import React from "react";

export default function Content() {
	return (
		<div className="p-8 bg-gray-100 h-full">
			<h1 className="text-3xl font-bold mb-6 text-center text-saleswizard">
				Saleswizard Plugins/Licenties Dashboard
			</h1>
			<h2 className="text-2xl font-semibold mb-4 text-saleswizardhover">
				Licenses:
			</h2>
			<ul className="list-disc list-inside mb-6 pl-4">
				<li className="mb-2">
					<span className="font-semibold">Naam:</span> Naam van de
					licentie die weergeven word in de popup
				</li>
				<li className="mb-2">
					<span className="font-semibold">Licentie:</span> Licentie
					code die weergegeven word in de popup
				</li>
			</ul>
			<h2 className="text-2xl font-semibold mb-4 text-saleswizardhover">
				Plugins:
			</h2>
			<ul className="list-disc list-inside pl-4">
				<li className="mb-2">
					<span className="font-semibold">Naam:</span> Naam van de
					plugin die weergeven word in de popup
				</li>
				<li className="mb-2">
					<span className="font-semibold">Slug:</span> Dit is de slug
					van de plugin, als je een plugin toevoegt van de publieke
					plugin repository van WordPress, dan MOET je de correcte
					slug invullen bijv: https://wordpress.org/plugins/akismet/
					hier is de slug "akismet". Anders word deze niet gevonden.
					Dit moet bij custom plugins overeenkomen met de naam van de
					folder in de plugins folder.
				</li>
				<li className="mb-2">
					<span className="font-semibold">Url:</span> Plugin url die
					je moet invullen bij custom plugins, deze worden geupload op
					plugins.sw-dev.nl/plugins, hier upload je de zip en dan vul
					je hier de naam van de zip file in voor die plugin (zonder
					.zip extensie)
				</li>
				<li className="mb-2">
					<span className="font-semibold">Versie:</span> Versie van de
					plugin. De plugins van de repository hebben dit niet nodig
					vanwege de automatische werking van WordPress. Voor custom
					plugins en/of betaalde plugins die niet op de WordPress repo
					zijn, en je toch wil dat ze via de SW Plugin manager als
					"updatable" weergeven worden, moet je hier het versienummer
					invullen van het ge-uploadde bestand.
				</li>
			</ul>
			<h2 className="mt-8 text-2xl font-semibold mb-4 text-saleswizardhover">
				Plugins updaten:
			</h2>
					<span className="font-semibold">Let op:</span> als je plugins update, zorg er dan voor dat je de zip die je upload dezelfde naam geeft als de huidige "url" optie van die plugin.
			<h2 className="mt-8 text-2xl font-semibold mb-4 text-saleswizardhover">
				Plugins uploaden:
			</h2>
					Via het upload tabje kan je nieuwe plugins uploaden of bestaande plugins updaten.
		</div>
	);
}
