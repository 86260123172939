import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";
import TopBar from "./TopBar/TopBar";
import Content from "./Content/Content";
import Licenses from "./Content/Licenses";
import Plugins from "./Content/Plugins";
import Upload from "./Content/Upload";

export default function Dashboard() {
  return (
    <Router>
      <Sidebar />
      <div className="content w-full flex flex-col">
        <TopBar />
        <Routes>
          <Route path="/licenses" element={<Licenses />} />
          <Route path="/plugins" element={<Plugins />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/" element={<Content />} />
        </Routes>
      </div>
    </Router>
  );
}
