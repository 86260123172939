import React, { useEffect, useState } from 'react';
import { auth } from './firebase-config';
import Login from './Login/Login';
import Dashboard from './Dashboard/Dashboard';
import './App.css';

function App() {
  // State to track if user is logged in
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        console.log('User is logged in', user);
        setLoggedIn(true);  // Set logged in state to true
      } else {
        console.log('No user logged in');
        setLoggedIn(false);  // Set logged in state to false
      }
    });

    return () => unsubscribe();  // Clean up subscription
  }, []);

  return (
    <div className="app h-screen flex flex-row bg-gray-50">
        {loggedIn ? <Dashboard /> : <Login />}
    </div>
  );
}

export default App;
