import React, { useState } from 'react';
import { auth } from '../firebase-config';
import { signInWithEmailAndPassword } from 'firebase/auth';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
            console.log('User logged in');
        } catch (error) {
            console.error('Login failed', error);
        }
    };

    return (
        <div className="flex items-center w-full justify-center min-h-screen bg-gray-100">
            <div className="bg-white p-8 rounded shadow-md w-full max-w-sm">
                <h2 className="text-2xl font-semibold mb-6 text-center">Login</h2>
                <input 
                    type="email" 
                    value={email} 
                    onChange={e => setEmail(e.target.value)} 
                    placeholder="Email" 
                    className="w-full p-2 mb-4 border border-gray-300 rounded"
                />
                <input 
                    type="password" 
                    value={password} 
                    onChange={e => setPassword(e.target.value)} 
                    placeholder="Password" 
                    className="w-full p-2 mb-4 border border-gray-300 rounded"
                />
                <button 
                    onClick={handleLogin} 
                    className="w-full bg-saleswizard text-white p-2 rounded hover:bg-saleswizardhover transition duration-200"
                >
                    Login
                </button>
            </div>
        </div>
    );
}

export default Login;
