import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCxZEp4XhHs-67pzkGWPr_Cq5ZCDydWkgE",
  authDomain: "dashboard-saleswizard.firebaseapp.com",
  projectId: "dashboard-saleswizard",
  storageBucket: "dashboard-saleswizard.appspot.com",
  messagingSenderId: "389928214274",
  appId: "1:389928214274:web:fbdf33eaea88cc6f37ac1b",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get the authentication service from the Firebase app
const auth = getAuth(app);

export { auth };
